<template>
  <section>
    <generic-input v-model="user.first_name"
                   @blur="$v.user.first_name.$touch()"
                   type="text"
                   name="firstname"
                   :placeholder="$t('firstname_field')"
                   :validations="[
                     {
                       condition: $v.user.first_name.$error && !$v.user.first_name.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.first_name.$error && !$v.user.first_name.minLength,
                       text: $t('min_characters', { number: 4 })
                     }
                   ]"
    />
    <generic-input v-model="user.last_name"
                   @blur="$v.user.last_name.$touch()"
                   type="text"
                   name="lastname"
                   :placeholder="$t('lastname_field')"
                   :validations="[
                     {
                       condition: $v.user.last_name.$error && !$v.user.last_name.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.last_name.$error && !$v.user.last_name.minLength,
                       text: $t('min_characters', { number: 4 })
                     }
                   ]"
    />

    <generic-dropdown :selected="user.country"
                      :options="countries"
                      :label="$t('country_field')"
                      @change="changeCountry"
    />
    <generic-dropdown :selected="user.region"
                      :options="selectedCountry.regions"
                      :label="$t('region_field')"
                      @change="changeRegion"
    />

    <generic-input v-model="user.city"
                   @blur="$v.user.city.$touch()"
                   type="text"
                   name="city"
                   :placeholder="$t('city_field')"
                   :validations="[
                     {
                       condition: $v.user.city.$error && !$v.user.city.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.city.$error && !$v.user.city.minLength,
                       text: $t('min_characters', { number: 4 })
                     }
                   ]"
    />

    <generic-input v-model="user.phone_number"
                   @blur="$v.user.phone_number.$touch()"
                   type="number"
                   name="phone_number"
                   :placeholder="$t('phone_number_field')"
                   :validations="[
                     {
                       condition: $v.user.phone_number.$error && !$v.user.phone_number.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.phone_number.$error && !$v.user.phone_number.minLength,
                       text: $t('min_characters', { number: 10 })
                     }
                   ]"
    />
  </section>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import GenericInput from '@/components/Core/Form/GenericInput'
import GenericDropdown from '@/components/Core/Form/GenericDropdown'

export default {
  name: 'UserInformationForm',
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
    GenericInput,
    GenericDropdown
  },
  computed: {
    selectedCountry () {
      return this.countries.find(c => c.value === this.user.country)
    }
  },
  data: () => ({
    user: {
      first_name: '',
      last_name: '',
      phone_number: '',
      country: 'ukraine',
      region: 'kyiv',
      city: ''
    },
    countries: [
      {
        label: 'Україна',
        value: 'ukraine',
        regions: [
          { label: 'АР Крим', value: 'crimea' },
          { label: 'Вінницька', value: 'vinnitsia' },
          { label: 'Волинська', value: 'volin' },
          { label: 'Дніпропетровська', value: 'dnipropetrovsk' },
          { label: 'Донецька', value: 'donetsk' },
          { label: 'Житомирська', value: 'zhitomir' },
          { label: 'Закарпатська', value: 'zakarpattia' },
          { label: 'Запорізька', value: 'zaporizzha' },
          { label: 'Івано-Франківська', value: 'iveno-frankivsk' },
          { label: 'Київська', value: 'kyiv' },
          { label: 'Кіровоградська', value: 'kirovograd' },
          { label: 'Луганська', value: 'lugansk' },
          { label: 'Львівська', value: 'lviv' },
          { label: 'Миколаївська', value: 'mikolaiv' },
          { label: 'Одеська', value: 'odesa' },
          { label: 'Полтавська', value: 'poltava' },
          { label: 'Рівненська', value: 'rivne' },
          { label: 'Сумська', value: 'sumi' },
          { label: 'Тернопільська', value: 'ternopil' },
          { label: 'Харківська', value: 'harkiv' },
          { label: 'Херсонська', value: 'herson' },
          { label: 'Хмельницька', value: 'khmelnitsk' },
          { label: 'Черкаська', value: 'cherkasi' },
          { label: 'Чернівецька', value: 'cernivtsi' },
          { label: 'Чернігівська', value: 'chernihiv' },
          { label: 'місто Севастополь', value: 'sevastopol_region' }
        ]
      },
      {
        label: 'Polska',
        value: 'poland',
        regions: [
          { label: 'Łódź', value: 'lodz' },
          { label: 'Świętokrzyskie', value: 'swietokrzyskie' },
          { label: 'Greater Poland', value: 'greater-poland' },
          { label: 'Kuyavian-Pomeranian', value: 'kuyavian-pomeranian' },
          { label: 'Lesser Poland', value: 'lesser-poland' },
          { label: 'Lublin', value: 'lublin' },
          { label: 'Lubusz', value: 'lubusz' },
          { label: 'Masovian', value: 'masovian' },
          { label: 'Opole', value: 'opole' },
          { label: 'Podlachian', value: 'podlachian' },
          { label: 'Pomeranian', value: 'pomeranian' },
          { label: 'Silesian', value: 'silesian' },
          { label: 'Subcarpathian', value: 'subcarpathian' },
          { label: 'Warmian-Masurian', value: 'warmian-masurian' },
          { label: 'West Pomeranian', value: 'west-pomeranian' }
        ]
      },
      {
        label: 'Deutschland',
        value: 'germany',
        regions: [
          { label: 'Baden-Württemberg', value: 'baden-wurttemberg' },
          { label: 'Bayern', value: 'bavaria' },
          { label: 'Berlin', value: 'berlin' },
          { label: 'Brandenburg', value: 'brandenburg' },
          { label: 'Bremen', value: 'bremen' },
          { label: 'Hamburg', value: 'hamburg' },
          { label: 'Hessen', value: 'hesse' },
          { label: 'Niedersachsen', value: 'lower-saxony' },
          { label: 'Mecklenburg-Vorpommern', value: 'mecklenburg-vorpommern' },
          { label: 'Nordrhein-Westfalen', value: 'north-rhine-westphalia' },
          { label: 'Rheinland-Pfalz', value: 'rhineland-palatinate' },
          { label: 'Saarland', value: 'saarland' },
          { label: 'Sachsen', value: 'saxony' },
          { label: 'Sachsen-Anhalt', value: 'saxony-anhalt' },
          { label: 'Schleswig-Holstein', value: 'schleswig-holstein' },
          { label: 'Thüringen', value: 'thuringia' }
        ]
      },
      {
        label: 'United States of America',
        value: 'usa',
        regions: [
          { label: 'Alaska', value: 'AK' },
          { label: 'Texas', value: 'TX' },
          { label: 'Alabama', value: 'AL' },
          { label: 'Arkansas', value: 'AR' },
          { label: 'Arizona', value: 'AZ' },
          { label: 'California', value: 'CA' },
          { label: 'Colorado', value: 'CO' },
          { label: 'Connecticut', value: 'CT' },
          { label: 'District of Columbia', value: 'DC' },
          { label: 'Delaware', value: 'DE' },
          { label: 'Florida', value: 'FL' },
          { label: 'Georgia', value: 'GA' },
          { label: 'Hawaii', value: 'HI' },
          { label: 'Iowa', value: 'IA' },
          { label: 'Idaho', value: 'ID' },
          { label: 'Illinois', value: 'IL' },
          { label: 'Indiana', value: 'IN' },
          { label: 'Kansas', value: 'KS' },
          { label: 'Kentucky', value: 'KY' },
          { label: 'Louisiana', value: 'LA' },
          { label: 'Massachusetts', value: 'MA' },
          { label: 'Maryland', value: 'MD' },
          { label: 'Maine', value: 'ME' },
          { label: 'Michigan', value: 'MI' },
          { label: 'Minnesota', value: 'MN' },
          { label: 'Missouri', value: 'MO' },
          { label: 'Mississippi', value: 'MS' },
          { label: 'Montana', value: 'MT' },
          { label: 'NorthCarolina', value: 'NC' },
          { label: 'NorthDakota', value: 'ND' },
          { label: 'Nebraska', value: 'NE' },
          { label: 'NewHampshire', value: 'NH' },
          { label: 'NewJersey', value: 'NJ' },
          { label: 'NewMexico', value: 'NM' },
          { label: 'Nevada', value: 'NV' },
          { label: 'NewYork', value: 'NY' },
          { label: 'Ohio', value: 'OH' },
          { label: 'Oklahoma', value: 'OK' },
          { label: 'Oregon', value: 'OR' },
          { label: 'Pennsylvania', value: 'PA' },
          { label: 'RhodeIsland', value: 'RI' },
          { label: 'SouthCarolina', value: 'SC' },
          { label: 'SouthDakota', value: 'SD' },
          { label: 'Tennessee', value: 'TN' },
          { label: 'Texas', value: 'TX' },
          { label: 'Utah', value: 'UT' },
          { label: 'Virginia', value: 'VA' },
          { label: 'Vermont', value: 'VT' },
          { label: 'Washington', value: 'WA' },
          { label: 'Wisconsin', value: 'WI' },
          { label: 'WestVirginia', value: 'WV' },
          { label: 'Wyoming', value: 'WY' }
        ]
      }
    ]
  }),
  validations: {
    user: {
      first_name: {
        required,
        minLength: minLength(4)
      },
      last_name: {
        required,
        minLength: minLength(4)
      },
      city: {
        required,
        minLength: minLength(4)
      },
      phone_number: {
        required,
        minLength: minLength(10)
      }
    }
  },
  mounted () {
    if (this.payload && Object.keys(this.payload).length !== 0) {
      this.user = this.payload
    }
  },
  methods: {
    resetUserForm () {
      this.user = {
        first_name: '',
        last_name: '',
        phone_number: '',
        country: 'ua',
        region: 'kyiv',
        city: ''
      }
    },
    changeCountry (newCountry) {
      this.user.country = newCountry
    },
    changeRegion (newRegion) {
      this.user.region = newRegion
    }
  },
  watch: {
    user: {
      deep: true,
      handler: function () {
        this.$v.$touch()
        this.$emit('validation-changed', !this.$v.$invalid)
      }
    }
  }
}
</script>
