var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('generic-input',{attrs:{"type":"text","name":"firstname","placeholder":_vm.$t('firstname_field'),"validations":[
                   {
                     condition: _vm.$v.user.first_name.$error && !_vm.$v.user.first_name.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.first_name.$error && !_vm.$v.user.first_name.minLength,
                     text: _vm.$t('min_characters', { number: 4 })
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.first_name.$touch()}},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('generic-input',{attrs:{"type":"text","name":"lastname","placeholder":_vm.$t('lastname_field'),"validations":[
                   {
                     condition: _vm.$v.user.last_name.$error && !_vm.$v.user.last_name.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.last_name.$error && !_vm.$v.user.last_name.minLength,
                     text: _vm.$t('min_characters', { number: 4 })
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.last_name.$touch()}},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('generic-dropdown',{attrs:{"selected":_vm.user.country,"options":_vm.countries,"label":_vm.$t('country_field')},on:{"change":_vm.changeCountry}}),_c('generic-dropdown',{attrs:{"selected":_vm.user.region,"options":_vm.selectedCountry.regions,"label":_vm.$t('region_field')},on:{"change":_vm.changeRegion}}),_c('generic-input',{attrs:{"type":"text","name":"city","placeholder":_vm.$t('city_field'),"validations":[
                   {
                     condition: _vm.$v.user.city.$error && !_vm.$v.user.city.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.city.$error && !_vm.$v.user.city.minLength,
                     text: _vm.$t('min_characters', { number: 4 })
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.city.$touch()}},model:{value:(_vm.user.city),callback:function ($$v) {_vm.$set(_vm.user, "city", $$v)},expression:"user.city"}}),_c('generic-input',{attrs:{"type":"number","name":"phone_number","placeholder":_vm.$t('phone_number_field'),"validations":[
                   {
                     condition: _vm.$v.user.phone_number.$error && !_vm.$v.user.phone_number.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.phone_number.$error && !_vm.$v.user.phone_number.minLength,
                     text: _vm.$t('min_characters', { number: 10 })
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.phone_number.$touch()}},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }